import React from 'react';
import { useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import InputMask from 'react-input-mask';
import { NCLoadingIndicator } from '@daupler/nexus-components';

import { useNavigate, useSearchParams } from 'react-router-dom';
import api from './api';
import queryClient from './queryClient';
import theme from './theme';
import Branding from './Branding';
import Form from './Form';
import Button from './Button';
import { getUrlParameter } from './utils';

function LoginErrorMessage({ error }) {
  switch (error.response?.data[0]) {
    default:
      return (
        <>
          This is not a valid dispatch link. If you need help, please email
          {' '}
          <a href="mailto:support@daupler.com">support@daupler.com</a>
          .
        </>
      );
  }
}

type LoginErrors = {
  name?: string[];
  phoneNumber?: string[];
};

function LoginForm() {
  const createSession = useMutation(api.createSession);
  const [errors, setErrors] = React.useState<LoginErrors>({});
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { linkToken } = useParams<RouterParams>();
  async function login({ name, phoneNumber }) {
    try {
      setErrors({});
      const response = await createSession.mutateAsync({
        linkToken,
        name,
        phoneNumber: {
          value: phoneNumber,
          region: 'US',
        },
      });
      // createSession returns the same data as loadSessionData
      queryClient.setQueryData(['sessionData'], response.data);
      navigate(`/${linkToken}/${response.data.session.token}?${searchParams.toString()}`);
    } catch (e) {
      setErrors(e.response.data);
    }
  }
  const [loginState, setLoginState] = React.useState({
    name: '',
    phoneNumber: '',
  });

  React.useEffect(() => {
    const name = getUrlParameter('n');
    const phoneNumber = getUrlParameter('p');
    const autoLogin = getUrlParameter('a');
    if (name && phoneNumber) {
      setLoginState({ name, phoneNumber });
      if (autoLogin) {
        login({ name, phoneNumber });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={(event) => { event.preventDefault(); login(loginState); }}>
      <Form.Element>
        <Form.Label className="nc-l-mt_300_mobile">Name</Form.Label>
        <input
          type="text"
          name="OtraPersona"
          value={loginState.name}
          placeholder="Your name"
          className={css`
            margin-top: var(--nds-l-utilities_50)!important;
          `}
          onChange={(e) => {
            setLoginState({
              ...loginState,
              name: e.target.value,
            });
          }}
        />
      </Form.Element>
      <Form.Element hasError={errors.phoneNumber?.length > 0} className="nc-l-mt_300_mobile">
        <Form.Label>Phone number</Form.Label>
        <InputMask
          type="text"
          name="Barcelona"
          autoComplete="off"
          mask="(999)999-9999"
          alwaysShowMask
          className={css`
            font-family: monospace !important;
            margin-top: var(--nds-l-utilities_50)!important;
          `}
          value={loginState.phoneNumber}
          onChange={(e) => {
            setLoginState({
              ...loginState,
              phoneNumber: e.target.value,
            });
          }}
        />
        {errors.phoneNumber?.map((error) => (
          <Form.Error key={error}>{error}</Form.Error>
        ))}
      </Form.Element>
      <Form.Element>
        <div
          className={css`
            padding-top: var(--nds-l-200);
          `}
        >
          {createSession.isLoading ? (
            <NCLoadingIndicator label="Loading" blockUi />
          ) : (
            <Button
              color="success"
              className={css`
                width: 100%;
              `}
              type="submit"
              disabled={
                createSession.isLoading
                || loginState.name === ''
                || loginState.phoneNumber.replace(/[^0-9]/g, '').length < 10
              }
            >
              Continue
            </Button>
          )}
        </div>
      </Form.Element>
    </form>
  );
}

const LoginWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 400px;
  width: 350px;
  border: 1px solid ${theme.palette.grey[300]};
  border-radius: 8px;
  padding: var(--nds-l-400);
  overflow: hidden;
  box-sizing: content-box;
`;

function Login() {
  const { linkToken } = useParams<{ linkToken: string | null }>();
  const { data, error, isLoading } = useQuery('validateToken', async () => {
    const result = await api.validateLinkToken(linkToken);
    return result.data;
  });

  return (
    <LoginWrapper>
      <div
        className={css`
          text-align: center;
          font-size: 22px;
        `}
      >
        <Branding />
      </div>
      {isLoading && (
        <div
          className={css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 3px;
          `}
        >
          <NCLoadingIndicator label="Loading" blockUi />
        </div>
      )}
      {error && (
        <div
          className={css`
            margin-top: var(--nds-l-400);
            font-size: 16px;
          `}
        >
          <LoginErrorMessage error={error} />
        </div>
      )}
      {data && (
        <>
          <div
            className={css`
              text-align: center;
              margin: var(--nds-l-utilities_50);
              padding-top: var(--nds-l-100);
              font-size: 13px;
            `}
          >
            for
          </div>
          <div
            className={css`
              text-align: center;
              padding-top: var(--nds-l-utilities_50);
              font-size: 18px;
              font-weight: 600;
            `}
          >
            {data.entity}
          </div>
          <LoginForm />
        </>
      )}
      <div
        className={css`
          position: absolute;
          bottom: var(--nds-l-200);
          left: 0;
          right: 0;
          text-align: center;
          & a {
            text-decoration: none;
            color: ${theme.palette.primary.main};
          }
        `}
      >
        <a href="https://daupler.com/terms-conditions" target="_blank" rel="noreferrer">
          Terms and Conditions
        </a>
        {' '}
        &middot;
        {' '}
        <a href="https://daupler.com/privacy-policy" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
      </div>
    </LoginWrapper>
  );
}

export default Login;
