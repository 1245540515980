import {
  FormField, NCButton, NCInputText, NCWell, debounce,
} from '@daupler/nexus-components';
import { useEffect, useRef, useState } from 'react';
import { ArcGisAddressCandidate } from '../../arcgis';
import { logger } from '../../logger';

type IncidentFormInformationProps = {
  formState: {
    contactName: FormField<string>;
    contactPhoneNumber: FormField<string>;
    contactPhoneExtension: FormField<string>;
    description: FormField<string>;
    location: FormField<string>;
    locationGeocoded: FormField<ArcGisAddressCandidate | undefined>;
  };
  getLocationCandidates: (location: string) => Promise<ArcGisAddressCandidate[]>;
  onChange: (name: string, value: unknown) => void;
  data: SessionData;
};

export function IncidentFormInformation({
  data,
  formState,
  getLocationCandidates,
  onChange,
}: IncidentFormInformationProps) {
  const [isGeocoding, setIsGeocoding] = useState(false);
  const [geocodeLocationError, setGeocodeLocationError] = useState('');
  const [locationCandidates, setLocationCandidates] = useState<ArcGisAddressCandidate[]>([]);
  const fetchLocationCandidates = async (location: string) => {
    if (!location) { return; }

    try {
      setIsGeocoding(true);
      setGeocodeLocationError('');
      const candidates = await getLocationCandidates(location);
      setLocationCandidates(candidates);
      if (candidates.length === 0) {
        setGeocodeLocationError('Could not geocode location');
      }
    } catch (err) {
      logger.error(err.message, err);
      setGeocodeLocationError(err.message);
      setLocationCandidates([]);
    } finally {
      setTimeout(() => setIsGeocoding(false), 125);
    }
  };
  const debouncedGetLocationCandidates = useRef(debounce(fetchLocationCandidates, 500));
  useEffect(() => {
    if (formState.location.value?.length === 0) {
      setLocationCandidates([]);
      return;
    }
    if (formState.location.value?.length < 6) { return; }
    if (formState.location.value === formState.locationGeocoded.value?.address) {
      return;
    }
    setLocationCandidates([]);
    debouncedGetLocationCandidates.current(formState.location.value);
  }, [debouncedGetLocationCandidates, formState.location.value, formState.locationGeocoded.value]);

  return (
    <>
      <NCInputText
        name={formState.description.name}
        label="Description of Request"
        autoComplete="off"
        value={formState.description.value}
        onChange={(event) => onChange(formState.description.name, event.target.value)}
        multiline
        rows={2}
        isValid={formState.description.isValid}
        hint={formState.description.message}
        required
        data-testid="field-description"
      />

      <NCWell className="nc-l-mt_400_mobile nc-l-pa_200_mobile">
        <NCInputText
          label="Location"
          value={formState.location.value}
          name={formState.location.name}
          autoComplete="off"
          isValid={formState.location.isValid}
          hint={formState.location.message}
          onChange={(event) => {
            if (event.target.id !== formState.locationGeocoded.value?.address) {
              onChange(formState.locationGeocoded.name, undefined);
            }
            onChange(formState.location.name, event.target.value);
          }}
          multiline
          labelAction={isGeocoding ? (
            <i className="fa-light fa-spinner dispatch-incident_form__location_loading" />
          ) : null}
          data-testid="field-location"
        />
        {geocodeLocationError && (
          <div className="nc-l-mt_100_mobile nc-t-grey_600 nc-t-sub_text_light">
            {geocodeLocationError}
          </div>
        )}
        {formState.location.value !== formState.locationGeocoded.value?.address
          && locationCandidates.map((candidate) => (
            <NCButton
              key={candidate.address}
              width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
              size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.XS]]}
              appearance={NCButton.appearances.INVERSE}
              color={NCButton.colors.PRIMARY}
              className="nc-l-mt_100_mobile"
              onClick={() => {
                onChange(formState.locationGeocoded.name, candidate);
                onChange(formState.location.name, candidate.address);
                setLocationCandidates([]);
              }}
              data-testid={`field-location_option-${candidate.address}`}
            >
              {candidate.address}
            </NCButton>
          ))}
      </NCWell>

      <NCWell className="nc-l-mt_400_mobile nc-l-pa_200_mobile">
        <NCInputText
          name={formState.contactName.name}
          label="Contact name"
          value={formState.contactName.value}
          onChange={(event) => onChange(formState.contactName.name, event.target.value)}
          autoComplete="off"
          isValid={formState.contactName.isValid}
          hint={formState.contactName.message}
          labelAction={(
            <NCButton
              appearance={NCButton.appearances.LINK}
              width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
              onClick={() => {
                onChange(formState.contactName.name, data.session.name);
                onChange(formState.contactPhoneNumber.name, data.session.phoneNumber);
              }}
            >
              Report as self
            </NCButton>
          )}
          data-testid="field-name"
        />

        <div className="nc-flex nc-flex--align_end nc-l-mt_400_mobile">
          <NCInputText
            className="nc-flex_item--3"
            name={formState.contactPhoneNumber.name}
            label="Callback phone number"
            value={formState.contactPhoneNumber.value}
            onChange={(event) => onChange(formState.contactPhoneNumber.name, event.target.value)}
            autoComplete="off"
            placeholder="(XXX)XXX-XXXX"
            tag={<span>🇺🇸 +1</span>}
            isValid={formState.contactPhoneNumber.isValid}
            hint={formState.contactPhoneNumber.message}
            data-testid="field-phone"
          />
          <NCInputText
            className="nc-l-ml_200_mobile nc-flex_item--2"
            name={formState.contactPhoneExtension.name}
            label="Extension"
            labelHidden
            tag={<span>Ext.</span>}
            value={formState.contactPhoneExtension.value}
            onChange={(event) => onChange(formState.contactPhoneExtension.name, event.target.value)}
            autoComplete="off"
            isValid={formState.contactPhoneExtension.isValid}
            hint={formState.contactPhoneExtension.message}
          />
        </div>
      </NCWell>
    </>
  );
}
