import { ArcGisAddressType } from './types';

export type ArcGisAddressCandidate = {
  address: string;
  score: number;
  location: {
    x: number;
    y: number;
  };
  attributes: {
    Addr_type: ArcGisAddressType;
    Type: string;
    LongLabel: string;
    ShortLabel: string;
    Country: string;
    Region: string;
    Subregion: string;
    RegionAbbr: string;
    City: string;
    Postal: string;
    StAddr: string;
    AddNum: string;
    Block: string;
    StDir: string;
    StPreDir: string;
    StPreType: string;
    StType: string;
    StName: string;
    StName1: string;
    StName2: string;
    SubAddr: string;
    UnitName: string;
    UnitType: string;
  };
};

type ArcGisGeocodeResult = {
  error?: {
    code: number;
    message: string;
  };
  candidates?: ArcGisAddressCandidate[];
};

// type ArcGisSuggestion = {
//   text: string;
//   magicKey: string;
//   isCollection: boolean;
// };

// type ArcGisSuggestResult = {
//   suggestions: ArcGisSuggestion[];
// };

export type ArcGisGeocodeParams = {
  category?: string;
  forStorage?: boolean;
  location?: string; // Of the form "lng,lat"
  countryCode?: string;
  maxLocations?: number;
  searchExtent?: string;
  SingleLine?: string;
};

const cache = {};

export async function findAddressCandidates(
  params: ArcGisGeocodeParams,
  controller: AbortController,
): Promise<ArcGisGeocodeResult> {
  const queryParams = {
    f: 'pjson',
    outFields: '*',
    maxLocations: 5,
    countryCode: 'US',
    ...params,
  };
  const url = `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?${
    Object.keys(queryParams)
      .map((_) => `${_}=${encodeURIComponent(queryParams[_])}`)
      .join('&')}`;
  if (cache[url]) return cache[url];
  const response = await fetch(url, {
    signal: controller.signal,
  });
  const result: ArcGisGeocodeResult = await response.json();
  cache[url] = result;
  return result;
}

//   if (this.cachedApiData[url]) {
//     return of(this.cachedApiData[url]);
//   }
//   return this.http.get<ArcGisGeocodeResult>(url).pipe(
//     map((value) => {
//       this.cachedApiData[url] = value;
//       if (value.error) throw new Error('findAddressCandidates error: ' + value.error.message);
//       return value;
//     })
//   );
// }

// suggest(address: string, options?: { category?: string; searchExtent?: string }) {
//   options = Object.assign({ category: '', searchExtent: '' }, options);
//   const lat = this.mapBounds.getCenter().lat();
//   const lng = this.mapBounds.getCenter().lng();
//   const url =
//     `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest` +
//     `?f=pjson` +
//     `&text=${encodeURIComponent(address)}` +
//     `&category=${options.category}` +
//     `&location=${lng}%2C${lat}` +
//     `&searchExtent=${encodeURIComponent(options.searchExtent)}` +
//     `&countryCode=US` +
//     `&maxLocations=5` +
//     `&token=${environment.arcGisApiKey}`;
//   if (this.cachedApiData[url]) {
//     return of(this.cachedApiData[url]);
//   }
//   return this.http.get<ArcGisSuggestResult>(url).pipe(
//     map((value) => {
//       this.cachedApiData[url] = value;
//       return value;
//     })
//   );
// }
