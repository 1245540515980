import React from 'react';
import TimeAgo from 'react-timeago';
import { useMutation } from 'react-query';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import {
  NCButton,
  NCContextMenu,
  NCInputText,
  NCLabel,
  NCModal,
  NCUserBadge,
} from '@daupler/nexus-components';
import api from '../../api';
import queryClient from '../../queryClient';
import './IncidentList.css';
import { SessionContext } from '../../contexts/SessionContext';

const INITIAL_MESSAGE_DATA = {
  isOpen: false,
  isRendered: false,
  body: '',
};

function ActionMenu({ incident }: { incident: Incident }) {
  const { linkToken, sessionToken } = useParams<RouterParams>();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState(INITIAL_MESSAGE_DATA);
  const sendMessage = useMutation(api.sendIncidentMessage);

  const getMenuOptions = () => {
    const menuItems = [[]];
    if (incident.leader) {
      menuItems[0].push({
        id: `action-send_to_leader-${incident.id}`,
        label: `Send Message to ${incident.leader.name}`,
        labelText: `Send Message to ${incident.leader.name}`,
        onClick: () => {
          setMessage({
            body: '',
            isOpen: true,
            isRendered: true,
          });
        },
        type: NCContextMenu.itemTypes.BUTTON,
      });
    }
    if (!incident.closed) {
      menuItems[0].push({
        id: `action-cancel_incident-${incident.id}`,
        label: 'Cancel Incident',
        labelText: 'Cancel Incident',
        onClick: () => {
          setMessage({
            isOpen: true,
            isRendered: true,
            body: 'This request has been canceled.',
          });
        },
        type: NCContextMenu.itemTypes.BUTTON,
      });
    }
    return menuItems;
  };

  return (
    <>
      {message.isRendered ? (
        <NCModal
          isOpen={message.isOpen}
          onClose={() => setMessage({ body: '', isOpen: false, isRendered: true })}
          onCloseFinished={() => setMessage(INITIAL_MESSAGE_DATA)}
          closeLabel="Close"
          title={`Message ${incident.leader?.name}`}
          footerChildren={(
            <div className="nc-l-pa_utilities_225_mobile nc-flex nc-flex--align_center nc-flex--justify_between">
              <NCButton
                color={NCButton.colors.GREY}
                width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                appearance={NCButton.appearances.OUTLINE}
                onClick={() => setMessage({ isOpen: false, body: '', isRendered: true })}
              >
                Cancel
              </NCButton>

              <NCButton
                color={NCButton.colors.SUCCESS}
                width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                appearance={NCButton.appearances.SOLID}
                disabled={sendMessage.isLoading || !message.body}
                type="submit"
                onClick={() => {
                  sendMessage.mutateAsync({
                    linkToken,
                    sessionToken,
                    incidentId: incident.id,
                    message: message.body,
                  });
                  toast('Message sent!', { toastId: 'message-sent', autoClose: 3000 });
                  setMessage(INITIAL_MESSAGE_DATA);
                  queryClient.refetchQueries();
                }}
              >
                Send Message
              </NCButton>
            </div>
          )}
        >
          <div className="nc-t-h5_regular_mobile nc-l-mt_200_mobile">
            Send a message to
            {' '}
            {incident.leader?.name}
            {' '}
            regarding the incident at
            {' '}
            {incident.location?.address.split(',')[0]}
            .
          </div>
          <NCInputText
            name="message"
            label="Message"
            value={message.body}
            onChange={(event) => {
              setMessage({
                ...message,
                body: event.target.value,
              });
            }}
            multiline
            rows={3}
            maxLength={160}
            autoFocus
            className="nc-l-mt_200_mobile"
            labelAction={(
              <span className="nc-t-grey_500">
                {message.body.length}
                /160
              </span>
            )}
          />
        </NCModal>
      ) : null}

      {incident.leader || !incident.closed ? (
        <NCContextMenu
          id={`actions-${incident.id}`}
          isOpen={open}
          options={getMenuOptions()}
          setIsOpen={setOpen}
          flyoverClassName="dispatch-incident_list__actions__flyover"
        >
          <span className="dispatch-incident_list__actions">
            Actions
          </span>
        </NCContextMenu>
      ) : null}
    </>
  );
}

function IncidentStatus({ incident }: { incident: Incident }) {
  if (!incident?.status) {
    return (
      <>
        <TimeAgo date={incident.time} minPeriod={30} />
        {' '}
        -
        {' '}
        Incident created
      </>
    );
  }
  return (
    <>
      <TimeAgo date={incident.status.time} minPeriod={30} />
      {' '}
      -
      {' '}
      {incident.status.message}
    </>
  );
}

function IncidentItem({ incident }: { incident: Incident }) {
  const lastMessage = incident.messages[incident.messages.length - 1];

  return (
    <div
      className={clsx('dispatch-incident_list__incident_item', {
        'dispatch-incident_list__incident_item--closed': incident.closed,
      })}
    >
      <div className="nc-flex nc-flex--justify_between nc-flex--align_start">
        <h3>
          <span className="nc-t-body_bold">
            {incident.location.address}
          </span>
          {' '}
          <span className="nc-t-body_light">
            <TimeAgo date={incident.time} minPeriod={30} />
          </span>
        </h3>
        <ActionMenu incident={incident} />
      </div>
      <div className="nc-l-my_100_mobile">
        <div className="nc-l-mb_utilities_50_mobile nc-t-h5_regular_mobile">
          {incident.description}
        </div>
        <div className="nc-t-sub_text_light nc-t-grey_700">
          Latest update:
          {' '}
          <IncidentStatus incident={incident} />
        </div>
        <div className="nc-t-sub_text_light nc-t-grey_700">
          Last message:
          {' '}
          {lastMessage ? (
            <>
              <TimeAgo date={lastMessage.time} minPeriod={30} />
              {' '}
              -
              {' '}
              <strong className="nc-t-grey_900">
                {lastMessage.body}
              </strong>
              {' '}
              {lastMessage.user && `from ${lastMessage.user}`}
            </>
          ) : (
            <>No messages</>
          )}
        </div>
      </div>
      <div>
        <NCLabel
          className="dispatch-incident_list__label_padding_override"
          label={(
            <NCUserBadge
              name={incident.contact.name}
              displayFullName={[NCUserBadge.breakpoints.MOBILE]}
              theme={NCUserBadge.themes.LIGHT}
              isExternalUser
            />
          )}
          appearance={NCLabel.appearances.OUTLINE}
          color={NCLabel.colors.GREY}
        />
        {' '}
        {incident.leader ? (
          <NCLabel
            className="dispatch-incident_list__label_padding_override"
            label={(
              <div className="nc-flex nc-flex--align_end">
                <NCUserBadge
                  name={incident.leader.name}
                  displayFullName={[NCUserBadge.breakpoints.MOBILE]}
                  theme={NCUserBadge.themes.LIGHT}
                />
                <span className="nc-t-sub_text_light nc-l-ml_100_mobile">{incident.leader.phoneNumber.localizedValue}</span>
              </div>
            )}
            appearance={NCLabel.appearances.OUTLINE}
            color={NCLabel.colors.GREY}
          />
        ) : (
          <span className="nc-l-ml_100_mobile">
            Awaiting assignment...
          </span>
        )}
      </div>
    </div>
  );
}

function IncidentList() {
  const data = React.useContext(SessionContext);

  return (
    <div className="nc-l-pa_200_mobile">
      <h2 className="nc-t-h3_medium_mobile nc-t-grey_900">Recent Incidents</h2>
      {data?.recentIncidents.data.map((incident) => (
        <IncidentItem key={incident.id} incident={incident} />
      ))}
      {data?.recentIncidents.data.length === 0 && (
        <div className="nc-l-mt_100_mobile nc-t-grey_900">
          No recent incidents.
        </div>
      )}
    </div>
  );
}

export default IncidentList;
