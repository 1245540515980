import clsx from 'clsx';
import { PropsWithChildren, ReactNode } from 'react';
import './FormExtension.css';

export function FormExtension({
  children,
  isOpen,
  toggle,
  title,
}: PropsWithChildren<{
  isOpen?: boolean;
  toggle: ReactNode;
  title: string;
}>) {
  return (
    <div className="dispatch-form_extension">
      <div className="dispatch-form_extension__header">
        <h2 className="nc-t-h5_light_mobile">
          {title}
        </h2>
        {toggle}
      </div>
      <div
        className={clsx('dispatch-form_extension__body', {
          'dispatch-form_extension__body--open': isOpen,
        })}
      >
        {children}
      </div>
    </div>
  );
}
