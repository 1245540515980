import styled from '@emotion/styled';
import { darken } from 'polished';

import theme, { Color } from './theme';

type ButtonProps = {
  color?: Color;
};

function getBackgroundColor(color: Color): string {
  return color === 'default' ? theme.palette.white : theme.palette[color].main;
}

export default styled.button<ButtonProps>`
  ${({ color = 'default' }) => ({
    borderColor: color === 'default' ? theme.palette.grey[500] : 'transparent',
    backgroundColor: getBackgroundColor(color),
    color: color === 'default' ? theme.palette.black : theme.palette.white,
  })}
  position: relative;
  border-radius: 5px;
  font-family: ${theme.bodyFont};
  font-size: 16px;
  font-weight: 700;
  padding: ${theme.spacing(2)}px;
  cursor: pointer;
  border-width: 1px;
  transition: background-color cubic-bezier(0.165, 0.84, 0.44, 1) 0.35s;
  overflow: hidden;
  &:hover {
    background: ${({ color = 'default' }) => darken(0.03, getBackgroundColor(color))};
  }
  &:active {
    transform: translateY(1px);
  }
  &:disabled {
    cursor: not-allowed;
    color: ${theme.palette.grey[700]};
    background-color: ${theme.palette.grey[200]};
    border-color: transparent;
  }
  outline: 0;
`;
