import { css } from '@emotion/css';

export type Color = 'default' | 'primary' | 'info' | 'success' | 'error';

const theme = {
  palette: {
    primary: {
      light: '#56ABDE',
      main: '#077CAC',
      dark: '#00507C',
    },
    white: '#FFFFFF',
    black: '#0E1B25',
    grey: {
      50: '#F7F8F8',
      100: '#E6E9EA',
      200: '#D1D5D7',
      300: '#C8CED0',
      400: '#ADB5B8',
      500: '#8A9599',
      600: '#758287',
      700: '#5F696D',
      800: '#3B4244',
      900: '#1F2224',
    },
    info: {
      light: '#BEF2E5',
      main: '#8DBFB3',
      dark: '#5E8F83',
    },
    success: {
      light: '#5db757',
      main: '#27862a',
      dark: '#005800',
    },
    error: {
      light: '#B81307',
      main: '#B81307',
      dark: '#800000',
    },
    brandingGradient: 'linear-gradient(90deg, #00507C 0%, #005800 100%)',
  },
  sectionSpacing: 3.25,
  headerFont: '"Montserrat", sans-serif',
  bodyFont: '"Nunito", sans-serif',
  appBarHeight: 56,
  formWidth: 500,
  spacing: (x: number) => Math.floor(2 ** (x + 1)),
};

export default theme;

export const container = css`
  max-width: 1200px;
  margin: 0 auto;
`;
