import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (retryCount: number, error: any) => {
        if (error?.response?.status === 400) return false;
        return retryCount < 3;
      },
    },
  },
});

export default queryClient;
