import React from 'react';
import { css } from '@emotion/css';

import brandingImage from './assets/DispatchAssistLogo.svg';

function Branding() {
  return (
    <img
      src={brandingImage}
      alt="Daupler logo"
      className={css`
        vertical-align: middle;
        height: 40px;
      `}
    />
  );
}

export default Branding;
