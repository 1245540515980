import React from 'react';
import { Route } from 'react-router';
import { css } from '@emotion/css';
// Globals has to use css from @emotion/react, thus we import it as _css to not
// clash with the preferred agnostic css function, because the css prop with
// typescript is a bit tricky without ejecting.
import { Global, css as _css } from '@emotion/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Routes } from 'react-router-dom';
import theme from './theme';
import Login from './Login';
import Session from './Session';
import { EmptyRoute } from './EmptyRoute';

function App() {
  return (
    <>
      <Global
        styles={_css`
          body,
          html {
            margin: 0;
            padding: 0;
            height: 100%;
            font-family: ${theme.bodyFont};
          }
        `}
      />
      <ToastContainer
        position="bottom-left"
        className={css`
          & .Toastify__toast {
            color: ${theme.palette.black};
            font-family: ${theme.bodyFont};
            font-weight: 600;
          }
          & .Toastify__progress-bar {
            background: ${theme.palette.brandingGradient};
          }
        `}
      />
      <Routes>
        <Route path="/:linkToken/:sessionToken" element={<Session />} />
        <Route path="/:linkToken" element={<Login />} />
        <Route path="/" element={<EmptyRoute />} />
      </Routes>
    </>
  );
}

export default App;
