import React, { useState } from 'react';
import { NCContextMenu, NCUserBadge } from '@daupler/nexus-components';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import Branding from '../Branding';
import api from '../api';
import './Navbar.css';
import { SessionContext } from '../contexts/SessionContext';

type NavbarProps = {
  className?: string;
};

export function Navbar({
  className,
}: NavbarProps) {
  const navigate = useNavigate();
  const { linkToken, sessionToken } = useParams<RouterParams>();
  const data = React.useContext(SessionContext);

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  return (
    <div className={clsx('dispatch-navbar', className)}>
      <div className="nc-flex nc-flex--align_center">
        <Branding />
      </div>

      <NCContextMenu
        id="navbar-user"
        isOpen={isUserMenuOpen}
        setIsOpen={setIsUserMenuOpen}
        flyoverClassName="dispatch-navbar__user_dropdown"
        options={[
          [
            {
              id: 'navbar-user-logout',
              label: 'Logout',
              labelText: 'Logout',
              onClick: async () => {
                await api.closeSession(linkToken, sessionToken);
                navigate(`/${linkToken}`);
              },
              type: NCContextMenu.itemTypes.BUTTON,
            },
          ],
        ]}
      >
        <div className="dispatch-navbar__user">
          <NCUserBadge
            name={data?.session.name}
            isExternalUser
            displayFullName={[NCUserBadge.breakpoints.TABLET]}
            theme={NCUserBadge.themes.LIGHT}
          />
          <i className="fa-solid fa-caret-down nc-t-grey_500 nc-l-ml_100_mobile" />
        </div>
      </NCContextMenu>
    </div>
  );
}
