export const getMapExtentStringFromMapBounds = (bounds: MapBounds): string => {
  const minLng = Math.min(bounds.ne.lng, bounds.sw.lng);
  const maxLng = Math.max(bounds.ne.lng, bounds.sw.lng);
  const minLat = Math.min(bounds.ne.lat, bounds.sw.lat);
  const maxLat = Math.max(bounds.ne.lat, bounds.sw.lat);

  return `${minLng},${minLat},${maxLng},${maxLat}`;
};

export const searchStringHasCity = (searchString: string): boolean => searchString.indexOf(', ') > -1;
