import axios, { AxiosResponse } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import { ArcGisAddressCandidate } from './arcgis';
import { ArcGisAddressType } from './types';

export type DispatchApi = {
  utils: {
    arcgis2compat: (candidate: ArcGisAddressCandidate) => LocationInput;
    isSupportedAddressType: (addressType: ArcGisAddressType) => boolean;
  };
  validateLinkToken: (linkToken: string) => Promise<AxiosResponse<{ entity: string; }>>;
  createSession: (data: CreateSessionInput) => Promise<AxiosResponse<SessionData>>;
  closeSession: (linkToken: string, sessionToken: string) => Promise<AxiosResponse<string>>;
  loadSessionData: (linkToken: string, sessionToken: string) => Promise<AxiosResponse<SessionData>>;
  dispatchIncident: (data: CreateIncidentInput) => Promise<AxiosResponse<SessionData>>;
  sendIncidentMessage: (data: SendIncidentMessageInput) => Promise<AxiosResponse<SessionData>>;
  classifyIncident: (
    data: ClassifyIncidentInput,
  ) => Promise<AxiosResponse<ClassifyIncidentResponse>>;
};

const client = applyCaseMiddleware(
  axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/flux/external_dispatch/`,
  }),
);

function buildDispatchToken(linkToken: string, sessionToken: string): string {
  return `${linkToken}:${sessionToken}`;
}

function convertCountry(country: string) {
  if (country.toUpperCase() === 'USA') return 'US';
  return country;
}

function isSupportedAddressType(addressType: ArcGisAddressType) {
  const supportedTypes = [
    ArcGisAddressType.SUBADDRESS,
    ArcGisAddressType.POINTADDRESS,
    ArcGisAddressType.STREETADDRESS,
    ArcGisAddressType.STREETINT,
  ];
  return supportedTypes.includes(addressType);
}

function arcgis2compat(candidate: ArcGisAddressCandidate): LocationInput {
  if (!candidate || !candidate.attributes) { throw new Error(`Incomplete address: ${candidate}`); }

  const attrs = candidate.attributes;
  const base = {
    raw: candidate.address,
    locality: attrs.City,
    administrativeLevel_1: attrs.RegionAbbr,
    administrativeLevel_2: attrs.Subregion,
    country: convertCountry(attrs.Country),
    postalCode: attrs.Postal,
    lat: candidate.location.y,
    lng: candidate.location.x,
  };
  switch (attrs.Addr_type) {
    case ArcGisAddressType.SUBADDRESS:
    case ArcGisAddressType.POINTADDRESS:
    case ArcGisAddressType.STREETADDRESS:
      return {
        ...base,
        streetNumber: attrs.AddNum,
        route: `${attrs.StPreDir} ${attrs.StName} ${attrs.StType} ${attrs.StDir}`.trim(),
        subpremise: attrs.SubAddr,
      };
    case ArcGisAddressType.STREETINT:
      return {
        ...base,
        route: attrs.StAddr,
      };
    default:
      throw new Error(`Unhandled address type: ${attrs.Addr_type}`);
  }
}

async function validateLinkToken(linkToken: string) {
  return client.post<{ entity: string }>('validate_link_token/', {
    linkToken,
  });
}

async function createSession(data: CreateSessionInput) {
  return client.post<SessionData>('create_session/', data);
}

async function closeSession(linkToken: string, sessionToken: string) {
  return client.post<string>('close_session/', {
    dispatchToken: buildDispatchToken(linkToken, sessionToken),
  });
}

async function loadSessionData(linkToken: string, sessionToken: string) {
  return client.post<SessionData>('load_session_data/', {
    dispatchToken: buildDispatchToken(linkToken, sessionToken),
  });
}

async function dispatchIncident(data: CreateIncidentInput) {
  return client.post<SessionData>('dispatch_incident/', {
    dispatchToken: buildDispatchToken(data.linkToken, data.sessionToken),
    ...data,
  });
}

async function sendIncidentMessage(data: SendIncidentMessageInput) {
  return client.post<SessionData>('send_incident_message/', {
    dispatchToken: buildDispatchToken(data.linkToken, data.sessionToken),
    ...data,
  });
}

async function classifyIncident(data: ClassifyIncidentInput) {
  return client.post<ClassifyIncidentResponse>('classify_incident/', {
    dispatchToken: buildDispatchToken(data.linkToken, data.sessionToken),
    ...data,
  });
}

export default {
  utils: {
    arcgis2compat,
    isSupportedAddressType,
  },
  validateLinkToken,
  createSession,
  closeSession,
  loadSessionData,
  dispatchIncident,
  sendIncidentMessage,
  classifyIncident,
} as DispatchApi;
