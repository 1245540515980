export enum ArcGisAddressType {
  SUBADDRESS = 'Subaddress',
  POINTADDRESS = 'PointAddress',
  STREETADDRESS = 'StreetAddress',
  STREETINT = 'StreetInt',
  STREETADDRESSEXT = 'StreetAddressExt',
  STREETMIDBLOCK = 'StreetMidBlock',
  STREETNAME = 'StreetName',
  LOCALITY = 'Locality',
  POSTALLOC = 'PostalLoc',
  POSTALEXT = 'PostalExt',
  POSTAL = 'Postal',
  POI = 'POI',
  LATLONG = 'LatLong',
  XY = 'XY',
  YX = 'YX',
  MGRS = 'MGRS',
  USNG = 'USNG',
}

export enum DatumType {
  gas_leak_form = 'gas_leak_form',
}

export enum ExtensionType {
  GAS = 'GAS',
}

export enum LocationServiceStatus {
  CONFIRMED = 'CONFIRMED',
  IN_SERVICE_AREA = 'IN_SERVICE_AREA',
  NEAR_SERVICE_AREA = 'NEAR_SERVICE_AREA',
  NOT_SERVICED = 'NOT_SERVICED',
  UNKNOWN = 'UNKNOWN',
}

export enum ClassifyResultType {
  category = 'category',
  facility = 'facility',
}
