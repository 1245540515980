import styled from '@emotion/styled';

import theme from './theme';

type ElementProps = {
  hasError?: boolean;
};

const Element = styled.div<ElementProps>`
  margin: ${theme.spacing(3)}px 0 0 0;
  & textarea,
  & input[type='text'] {
    appearance: none;
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: ${theme.spacing(2)}px;
    margin: 0;
    font-family: ${theme.bodyFont};
    font-size: 18px;
    border-radius: 3px;
    border: 1px solid;
    line-height: 22px;
    color: ${theme.palette.grey[900]};
    &:focus {
      box-shadow: 0 0 5px rgba(81, 203, 238, 1);
    }
    outline: 0;
    ${({ hasError = false }) => ({
    borderColor: hasError ? theme.palette.error.main : theme.palette.grey[400],
  })}
  }
`;

const Label = styled.label`
  display: block;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 2px;
`;

const Suggestion = styled.div`
  font-size: 13px;
  margin: ${theme.spacing(1)}px;
  & button {
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
    color: ${theme.palette.primary.dark};
    padding: ${theme.spacing(1)}px;
    cursor: pointer;
    outline: 0;
    border-radius: 4px;
  }
  &:hover {
    button {
      background: ${theme.palette.grey[50]};
    }
  }
`;

const Error = styled.div`
  margin: ${theme.spacing(1)}px;
  color: ${theme.palette.error.main};
`;

const exports = {
  Element, Label, Suggestion, Error,
};
export default exports;
