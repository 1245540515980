import React from 'react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import '@daupler/nexus-components/dist/nexus-components.css';

import './polyfill';
import queryClient from './queryClient';
import App from './App';
import { logger } from './logger';

if (process.env.REACT_APP_ENVIRONMENT?.toUpperCase() !== 'LOCAL') {
  try {
    Sentry.init({
      dsn: 'https://33afd9c2d180695a4555cbb6dd075dd6@o184264.ingest.us.sentry.io/4507612751396864',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      environment: process.env.REACT_APP_ENVIRONMENT,
    });
  } catch (err) {
    logger.warn('Unable to initialize Sentry!');
  }
}

const root = createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <App />
      </Router>
    </QueryClientProvider>
  </React.StrictMode>,
);
