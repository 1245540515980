import { NCInputRadioGroup } from '@daupler/nexus-components';

export function BooleanRadioGroup({
  className,
  disabled,
  hint,
  label,
  name,
  options,
  onChange,
  value,
  valueMap,
}: {
  className?: string;
  disabled?: boolean;
  hint?: string;
  label: string;
  name: string;
  options: { label: string; id: string; hint?: string | JSX.Element; 'data-testid'?: string }[];
  onChange: (value: boolean | undefined) => void;
  value: boolean | undefined | null;
  valueMap: Record<string, boolean | undefined>;
}) {
  const booleanToId = () => Object.entries(valueMap).find(([, v]) => v === value)?.[0];

  const idToBoolean = (
    changeValue: string,
  ) => Object.entries(valueMap).find(([k]) => k === changeValue)?.[1];

  return (
    <NCInputRadioGroup
      className={className}
      disabled={disabled}
      label={label}
      name={name}
      options={options}
      onChange={(event) => {
        onChange(idToBoolean(event.target.id));
      }}
      value={booleanToId()}
      hint={hint}
    />
  );
}
