import React from 'react';
import { observer } from 'mobx-react';
import {
  FormField,
  NCInputCheckbox,
  NCInputText,
} from '@daupler/nexus-components';
import { DatumType, ExtensionType } from './types';
import { FormExtension } from './FormExtension';
import { BooleanRadioGroup } from './components/BooleanRadioGroup';
import { SessionContext } from './contexts/SessionContext';

export const GasQuestionaire = observer(({
  data,
  form,
  onDatumChange,
  onOdorComplaintChange,
}: {
  data?: SessionData;
  form: {
    datums: FormField<IncidentFormDatum[]>;
    odorComplaint: FormField<boolean>;
  };
  onDatumChange: (value: Partial<IncidentFormDatum>) => void;
  onOdorComplaintChange: (value: boolean) => void;
}) => {
  const sessionData = data ?? React.useContext(SessionContext);
  const datum = form.datums.value.find((d) => d.type === DatumType.gas_leak_form);
  const hasExtension = sessionData?.extensions?.some(
    (extension) => extension.extensionType === ExtensionType.GAS,
  );

  const handleDatumChange = (value: Partial<GasLeakFormDatum>) => {
    onDatumChange({
      ...datum,
      value: {
        ...datum.value,
        ...value,
      },
    });
  };

  const handleLocationChange = (value: string | null) => {
    if (value === null) {
      handleDatumChange({ locations: [] });
    } if (datum.value.locations?.includes(value)) {
      handleDatumChange({
        locations: datum.value.locations?.filter((location) => location !== value),
      });
    } else {
      handleDatumChange({
        locations: [
          ...datum.value.locations ?? [],
          value,
        ],
      });
    }
  };

  if (!hasExtension || !datum) {
    return null;
  }

  return (
    <div data-testid="form-gas-extension">
      <FormExtension
        toggle={(
          <label htmlFor="odor-complaint-optin">
            <input
              type="checkbox"
              name="odor-complaint-optin"
              id="odor-complaint-optin"
              onChange={() => onOdorComplaintChange(!form.odorComplaint.value)}
              checked={form.odorComplaint.value}
            />
            {' '}
            Yes
          </label>
        )}
        isOpen={form.odorComplaint.value}
        title="Gas odor/leak?"
      >
        <BooleanRadioGroup
          className="nc-l-mt_200_mobile"
          label="Is the customer home?"
          name="gas_home"
          options={[
            {
              id: 'contact_on_premise-yes',
              label: 'Yes',
            },
            {
              id: 'contact_on_premise-no',
              label: 'No',
            },
          ]}
          onChange={(value) => {
            handleDatumChange({ contact_on_premise: value });
          }}
          value={datum.value.contact_on_premise}
          valueMap={{
            'contact_on_premise-yes': true,
            'contact_on_premise-no': false,
          }}
        />

        <div className="nc-t-body_medium_mobile nc-t-grey_900 nc-l-mt_200_mobile">
          Leak Location
        </div>
        <NCInputCheckbox
          name="gas_leak_location-inside"
          label="Inside"
          checked={datum.value.locations?.includes('inside')}
          onChange={() => handleLocationChange('inside')}
        />
        <NCInputCheckbox
          name="gas_leak_location-outside"
          label="Outside"
          checked={datum.value.locations?.includes('outside')}
          onChange={() => handleLocationChange('outside')}
        />
        <NCInputCheckbox
          name="gas_leak_location-unsure"
          label="I don't know"
          checked={datum.value.locations === null}
          onChange={() => handleLocationChange(null)}
        />

        <BooleanRadioGroup
          className="nc-l-mt_200_mobile"
          label="Do you hear noise of gas escaping?"
          name="gas_audible"
          options={[
            {
              id: 'gas_audible-yes',
              label: 'Yes',
            },
            {
              id: 'gas_audible-no',
              label: 'No',
            },
            {
              id: 'gas_audible-unsure',
              label: 'I don\'t know',
            },
          ]}
          onChange={(value) => {
            handleDatumChange({ audible: value });
          }}
          value={datum.value.audible}
          valueMap={{
            'gas_audible-yes': true,
            'gas_audible-no': false,
            'gas_audible-unsure': null,
          }}
        />

        <BooleanRadioGroup
          className="nc-l-mt_200_mobile"
          label="Is there construction nearby?"
          name="gas_construction_near"
          options={[
            {
              id: 'gas_construction_near-yes',
              label: 'Yes',
            },
            {
              id: 'gas_construction_near-no',
              label: 'No',
            },
            {
              id: 'gas_construction_near-unsure',
              label: 'I don\'t know',
            },
          ]}
          onChange={(value) => { handleDatumChange({ construction_nearby: value }); }}
          value={datum.value.construction_nearby}
          valueMap={{
            'gas_construction_near-yes': true,
            'gas_construction_near-no': false,
            'gas_construction_near-unsure': null,
          }}
        />

        <BooleanRadioGroup
          label="Any injuries?"
          name="gas_injuries"
          options={[
            {
              id: 'gas_injuries-yes',
              label: 'Yes',
            },
            {
              id: 'gas_injuries-no',
              label: 'No',
            },
            {
              id: 'gas_injuries-unsure',
              label: 'I don\'t know',
            },
          ]}
          onChange={(value) => {
            handleDatumChange({ injuries: value });
          }}
          value={datum.value.injuries}
          valueMap={{
            'gas_injuries-yes': true,
            'gas_injuries-no': false,
            'gas_injuries-unsure': null,
          }}
        />

        <NCInputText
          className="nc-l-mt_200_mobile"
          label="Severity of gas leak"
          name="gas_severity"
          onChange={(event) => {
            handleDatumChange({ severity: event.target.value });
          }}
          multiline
          rows={2}
          value={datum.value.severity}
        />

        <NCInputText
          className="nc-l-mt_200_mobile"
          label="Type and size of line cut"
          name="gas_type_and_size"
          onChange={(event) => {
            handleDatumChange({ line_info: event.target.value });
          }}
          multiline
          rows={2}
          value={datum.value.line_info}
        />

        <BooleanRadioGroup
          className="nc-l-mt_200_mobile"
          label="Was 811 contacted to locate gas lines in advance of cut?"
          name="gas_811"
          options={[
            {
              id: 'gas_811-yes',
              label: 'Yes',
            },
            {
              id: 'gas_811-no',
              label: 'No',
            },
            {
              id: 'gas_811-unsure',
              label: 'I don\'t know',
            },
          ]}
          onChange={(value) => {
            handleDatumChange({ area_marked: value });
          }}
          value={datum.value.area_marked}
          valueMap={{
            'gas_811-yes': true,
            'gas_811-no': false,
            'gas_811-unsure': null,
          }}
        />
      </FormExtension>
    </div>
  );
});
