import { AxiosError } from 'axios';

export function getUrlParameter(name: string): string | null {
  const result = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${result}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function getStatusCodeFromAxiosError(error: unknown): Number {
  if (error && typeof error === 'object' && 'response' in error) {
    return (error as AxiosError).response.status;
  }
  if (error && typeof error === 'object' && 'request' in error) {
    return (error.request as XMLHttpRequest).status;
  }
  return 500;
}
