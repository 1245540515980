import { ClassifyResultType } from '../types';

type ResultTransformOptions = {
  incidentHandlers: IncidentHandler[];
  legacyCategories: LegacyCategory[];
  result: ClassifyIncidentResult & { id: string };
};

type FormOption = {
  label: string;
  icon: string;
  id: string;
  hint: string;
  'data-testid': string;
  type: ClassifyResultType;
  handlerId: string;
};

function transformCategoryResult({
  incidentHandlers,
  legacyCategories,
  result,
}: ResultTransformOptions) {
  const { legacyCategoryName } = result;
  const legacyCategory = legacyCategories
    .find((lc) => lc.name === legacyCategoryName);
  if (!legacyCategory) { throw new Error('Could not match legacy category to suggested category'); }
  const { overrideDispatchData: { handlerId: overrideHandlerId } } = result;
  const { defaultDispatchData: { handlerId } } = legacyCategory;
  const resolvedHandlerId = overrideHandlerId ?? handlerId;
  const handler = incidentHandlers.find((h) => h.id === resolvedHandlerId);
  if (!handler) { throw new Error('Could not find handler for category'); }
  return {
    label: result.displayName,
    id: result.id,
    hint: `Dispatches to: ${handler.displayName}`,
    'data-testid': result.id,
    type: result.resultType,
    icon: 'fa-crystal-ball',
    handlerId: resolvedHandlerId,
  };
}

function transformFacilityResult({
  result,
  incidentHandlers,
}: ResultTransformOptions) {
  if (!result.overrideDispatchData.handlerId) { throw new Error('Facility result did not provide override handler'); }
  const handler = incidentHandlers.find((h) => h.id === result.overrideDispatchData.handlerId);
  if (!handler) { throw new Error('Could not find handler for category'); }
  return {
    label: result.displayName,
    id: result.id,
    hint: `Dispatches to: ${handler.displayName}`,
    'data-testid': result.id,
    type: result.resultType,
    icon: 'fa-building',
    handlerId: handler.id,
  };
}

export function classifyResultToFormOption(
  result: ClassifyIncidentResult & { id: string },
  sessionData: SessionData,
): FormOption | null {
  try {
    switch (result.resultType) {
      case ClassifyResultType.category:
        return transformCategoryResult({ result, ...sessionData });
      case ClassifyResultType.facility:
        return transformFacilityResult({ result, ...sessionData });
      default:
        return null;
    }
  } catch (err) {
    return null;
  }
}
